/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import Select from "../../../components/atoms/Select";
import TextArea from "../../../components/atoms/TextArea";
import Button from "../../../components/atoms/Button";
import Loading from "../../../components/atoms/Loading";
import {
  fetchAllTierConfigs,
  updateTierConfig,
} from "../../../store/itadmin.actions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import tw from "twin.macro";
import Toast from "../../../components/molecules/Toast";
import { clearError, clearMessage } from "../../../store/itadmin.reducer";
import ConfirmationDialog from "../../../components/molecules/ConfirmationDialog";

type Props = {
  children?: ReactNode;
};

const TierConfig: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { configs, status, error, message } = useAppSelector(
    (state) => state.itadmin.tierConfig
  );
  const [currentTierConfigKey, setCurrentTierConfigKey] = useState<string>("");
  const [selectedConfigData, setSelectedConfigData] = useState<string>("");
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [badConfigMessage, setBadConfigMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetch = async () => {
      const data = await dispatch(fetchAllTierConfigs()).unwrap();
      setCurrentTierConfigKey(data?.[0].key);
      setSelectedConfigData(data?.[0].value);
    };
    fetch();
  }, []);

  const updateHandler = () => {
    setBadConfigMessage(undefined);

    const updatedData = {
      key: currentTierConfigKey,
      value: selectedConfigData,
    };
    dispatch(updateTierConfig(updatedData))
    .unwrap()
    .then((configResponse) => {
      if (configResponse.data.message) {
        setBadConfigMessage(configResponse.data.message);
      }
    });
  };

  const revertHandler = () => {
    setSelectedConfigData(
      configs?.find((item) => item.key === currentTierConfigKey)?.value ?? ""
    );
    setShowDiscardDialog(false);
  };

  return (
    <>
      {showDiscardDialog && (
        <ConfirmationDialog
          onConfirm={revertHandler}
          onCancel={() => setShowDiscardDialog(false)}
          title="Undo?"
        >
          Discard unsaved changes?
        </ConfirmationDialog>
      )}
      {message && (
        <Toast
          type="success"
          message={message}
          onConfirm={() => dispatch(clearMessage())}
        />
      )}
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearError())}
        />
      )}
      <div className="px-8 py-2 text-xs flex flex-col relative gap-2">
        {["loading", "updating"].includes(status) && (
          <Loading>{status === "loading" ? "Loading" : "Updating"}</Loading>
        )}
        {badConfigMessage && <div className="whitespace-pre-wrap text-red-600">{badConfigMessage}</div>}
        <h3 className="font-bold">Config Key</h3>
        <div>
          <Select
            name="configKey"
            value={currentTierConfigKey}
            onChange={(value: string) => {
              setCurrentTierConfigKey(value);
              setSelectedConfigData(
                configs?.find((item) => item.key === value)?.value ?? ""
              );
            }}
            data={configs?.map((item) => ({
              value: item.key,
              label: item.key,
            }))}
          />
        </div>
        <TextArea
          rows={30}
          value={selectedConfigData}
          onChange={(e) => setSelectedConfigData(e.target.value)}
        />
        <div className="flex gap-8">
          <Button onClick={updateHandler} className="self-start">
            Save
          </Button>
          <Button
            onClick={() => setShowDiscardDialog(true)}
            css={tw`self-start bg-gray-500`}
          >
            Revert
          </Button>
        </div>
      </div>
    </>
  );
};

export default TierConfig;
