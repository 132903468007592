import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWithAuth, errorHandler } from "../app/axios";
import {
  CurrentContext,
  CurrentUserContext,
  ErrorResponse,
  User,
  UserPreferences,
} from "../types/types";
import {
  setPrimeInventoryFilters,
  setProductDetailsFromHistory,
} from "./product.reducer";
import { getSiteSelectionList } from "./app.actions";
import { AxiosError } from "axios";
import { RootState } from "./store";
import { setCurrentCustomer } from "./customer.reducer";
import { getBrowseHistory } from "./product.actions";
import { fetchCartSummary, fetchLastShipTo } from "./shoppingCart.actions";
import { fetchCustomerParts } from "./parts.actions";

// Generates pending, fulfilled and rejected action types
export const fetchUser = createAsyncThunk<
  CurrentContext,
  string | undefined,
  { rejectValue: string | ErrorResponse }
>(
  "user/fetchUsers",
  async (customerIdSearchParam, { dispatch, rejectWithValue }) => {
    try {
      const customerId = window.localStorage.getItem("ntpLastCustomer");
      const query =
        customerIdSearchParam || customerId
          ? `?customerId=${customerIdSearchParam ?? customerId}`
          : "";
      const response = await axiosWithAuth.get<CurrentUserContext>(
        `${process.env.REACT_APP_API_URL}/user/current_context_with_customer${query}`
      );
      const userAuthorities = response.data?.user?.authorities;
      if (!userAuthorities) {
        throw new AxiosError("User has no authorities");
      }
      if (response.data?.currentCustomer) {
        dispatch(setCurrentCustomer(response.data.currentCustomer));
        dispatch(fetchCartSummary(response.data.currentCustomer.id));
        dispatch(fetchCustomerParts(response.data.currentCustomer.id));
        dispatch(getSiteSelectionList(response.data.currentCustomer.id));
        dispatch(fetchLastShipTo(response.data.currentCustomer.id));
      } else {
        dispatch(getSiteSelectionList(""));
      }

      dispatch(
        setPrimeInventoryFilters({
          oddLengths: response.data?.userPreferences?.showOddLengths ?? false,
          site: "ALL",
        })
      );
      const history = await dispatch(getBrowseHistory()).unwrap();
      if (history.length > 0) {
        dispatch(setProductDetailsFromHistory(history[0].productIndex));
      }

      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const getUserPreferences = createAsyncThunk<
  UserPreferences,
  undefined,
  { rejectValue: string | ErrorResponse }
>("user/getUserPreferences", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get<UserPreferences>(
      `${process.env.REACT_APP_API_URL}/user/preferences`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

interface UpdateUserPreferencesRequest {
  preferences: Partial<UserPreferences>;
}

export const updateUserPreferences = createAsyncThunk<
  UserPreferences,
  UpdateUserPreferencesRequest,
  { rejectValue: string | ErrorResponse }
>(
  "user/updateUserPreferences",
  async (details: UpdateUserPreferencesRequest, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/user/update_preferences`,
        details.preferences
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const updateUserInfo = createAsyncThunk<
  User,
  Partial<User>,
  { rejectValue: string | ErrorResponse }
>("user/updateUserInfo", async (userInfo, { rejectWithValue }) => {
  try {
    const response = axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/user`,
      userInfo
    );
    return (await response).data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const updateDefaultPriceUnit = createAsyncThunk<
  UserPreferences,
  { defaultPriceUnit: "CFT" | "CWT" },
  { rejectValue: string | ErrorResponse }
>(
  "user/updateDefaultPriceUnit",
  async (params, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const preferences = state.user.currentContext?.userPreferences;
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/user/update_preferences`,
        { ...preferences, ...params }
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);
