/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import AnimatedLoadingSpinner from "../../../../../components/atoms/AnimatedLoadingSpinner";
import { numberFormatter } from "../../../../../helpers/numberFormat";
import { fetchOpenCarts } from "../../../../../store/shoppingCart.actions";
import { setCurrentCustomerDetails } from "../../../../../store/customer.actions";
import TextButton from "../../../../../components/atoms/TextButton";
import { dateToString, formatDateToShort } from "../../../../../helpers/dateHelpers";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
};

const OpenShoppingCartsDialog: React.FC<Props> = ({
  onCancel,
  ...remainingProps
}) => {
  const initialLoad = useRef(true);
  const dispatch = useAppDispatch();
  const { openCarts } = useAppSelector((state) => state.shoppingCart);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialLoad.current) {
      setIsLoading(true);
      dispatch(fetchOpenCarts(false)).finally(() => setIsLoading(false));
      initialLoad.current = false;
    }
  }, []);

  const formatDate = (d: string) => {
    const date = new Date(d);
    return formatDateToShort(dateToString(date));
  };

  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#cccccc77] z-[100]`,
      ]}
      {...remainingProps}
    >
      <div
        css={[tw`bg-nucor-green p-[7px] min-w-[50ch] w-[120ch] text-xs`]}
        onClick={(e) => e.stopPropagation()}
      >
        <div css={tw`flex justify-between`}>
          <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>
            View Open Shopping Carts
          </h2>
          <button
            type="button"
            onClick={onCancel}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
          >
            &times;
          </button>
        </div>
        <div css={tw`bg-white p-1 overflow-auto max-h-[500px]`}>
          {isLoading && <AnimatedLoadingSpinner />}
          {!isLoading && (
            <>
              <table className="ntp-portal-table" css={tw`w-full text-xs`}>
                <thead>
                  <tr>
                    <th>Save Date</th>
                    <th css={tw`w-2/5`}>Customer</th>
                    <th>Items Total</th>
                    <th>Wgt Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {openCarts?.map((cart) => {
                    return (
                      <tr key={cart.id}>
                        <td>{formatDate(cart.updatedDate)}</td>
                        <td>{cart.customerId} - {cart.customerName}</td>
                        <td css={tw`text-right`}>{cart.totalItems}</td>
                        <td css={tw`text-right`}>{numberFormatter(cart.totalWeight)}</td>
                        <td css={tw`text-center`}>
                          <TextButton
                            onClick={() => {
                              dispatch(
                                setCurrentCustomerDetails(cart.customerId)
                              );
                              onCancel();
                            }}
                          >
                            View
                          </TextButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p css={tw`mt-2`}>
                Clicking on the View link will open the selected customer&apos;s cart.
              </p>
            </>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default OpenShoppingCartsDialog;
