import { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { NavigateFunction, To } from "react-router-dom";
import { ErrorResponse } from "../types/types";
import { isBlobError } from "../types/predicates";
import { blobToErrorResponse } from "../helpers/blobToErrorResponse";

let observers: Array<() => void> = [];
let navigation: Array<NavigateFunction> = [];

export const axiosObservable = Object.freeze({
  notify: () => observers.forEach((obs) => obs()),
  subscribe: (func: () => void) => {
    observers.push(func);
  },
  unsubscribe: (func: () => void) => {
    observers = observers.filter((obs) => obs === func);
  },
});

export const navigationObservable = Object.freeze({
  notify: (to: To) => navigation.forEach((obs) => obs(to)),
  subscribe: (func: NavigateFunction) => {
    navigation.push(func);
  },
  unsubscribe: (func: NavigateFunction) => {
    navigation = navigation.filter((obs) => obs === func);
  },
});

export const axiosWithAuth = axios.create();
axiosWithAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: unknown) => {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400: {
          return Promise.reject(error);
        }
        case 401: {
          if (error.config.url?.includes("current_context")) {
            return Promise.reject(error);
          }
          axiosObservable.notify();
          return Promise.reject(error);
        }
        case 403: {
          navigationObservable.notify("/forbidden");
          return Promise.reject(error);
        }
        case 404: {
          return Promise.reject(error);
        }
        case 423: {
          navigationObservable.notify("/restricted");
          return Promise.reject(error);
        }
        case 500: {
          navigationObservable.notify("/500");
          return Promise.reject(error);
        }
        default:
          if (error.config.url?.includes("current_context")) {
            return Promise.reject(error);
          }
          navigationObservable.notify("/500");
          return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export const authInterceptor = (
  getAccessTokenSilently: () => Promise<
    GetTokenSilentlyVerboseResponse | string
  >
) => {
  axiosWithAuth.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (!config.headers) return;
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export const errorHandler = <R>(error: unknown, rejectWithValue: (value: string | ErrorResponse) => R ): R => {
  if (error instanceof AxiosError) {
    switch (error.response?.status) {
      case 400:
        return rejectWithValue(error.response?.data);
      case 401:
        return rejectWithValue("Unauthorized");
      case 403:
        return rejectWithValue("Forbidden");
      case 404:
        return rejectWithValue(error.response?.data);
      case 423:
        return rejectWithValue(error.response?.data);
      case 500:
        return rejectWithValue("Internal server error");
      default:
        return rejectWithValue(error.message ?? "An unexpected error occured.");
    }
  } else {
    return rejectWithValue("An unexpected error occured.");
  }
};

export const blobErrorHandler = async <R>(error: unknown, rejectWithValue: (value: string | ErrorResponse) => R ): Promise<R> => {
  if (error instanceof AxiosError) {
    if (isBlobError(error.response?.data) && error.response) {
      error.response.data = await blobToErrorResponse(error.response.data);
    }
    return errorHandler(error, rejectWithValue);
  } else {
    return rejectWithValue("An unexpected error occured.");
  }
};