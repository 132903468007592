import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWithAuth, blobErrorHandler, errorHandler } from "../app/axios";
import {
  CustomerIndex,
  ShipToRequest,
  AccountSummary,
  Customer_Customer,
  SalesTeam,
  ErrorResponse,
  CustomerSummaryItemResult,
  CustomerSummaryRequest,
  TotalByRep
} from "../types/types";
import { fetchCartSummary, fetchLastShipTo } from "./shoppingCart.actions";
import { fetchCustomerParts } from "./parts.actions";
import { getSiteSelectionList } from "./app.actions";
import { objectToQueryString } from "../helpers/objectToQueryString";
import { downloadFile } from "../helpers/downloadExcel";

export const setCurrentCustomerDetails = createAsyncThunk<
  Customer_Customer,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "customer/setCurrentCustomerDetails",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/customer/${id}`
      );
      dispatch(fetchCartSummary(id));
      dispatch(fetchCustomerParts(id));
      dispatch(getSiteSelectionList(id));
      dispatch(fetchLastShipTo(id));

      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const fetchCustomers = createAsyncThunk<
  CustomerIndex[],
  undefined,
  { rejectValue: string | ErrorResponse }
>("customer/fetchCustomers", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/customer`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type RequestNewShipToRequest = {
  customerId: string;
  body: ShipToRequest;
};

export const requestNewShipTo = createAsyncThunk<
  ShipToRequest,
  RequestNewShipToRequest,
  { rejectValue: string | ErrorResponse }
>("customer/requestNewShipTo", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/ship_to_request/${params.customerId}`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const fetchCustomerSummary = createAsyncThunk<
  AccountSummary,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "customer/fetchCustomerSummary",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/customer/${customerId}/summary`
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const fetchSalesTeam = createAsyncThunk<
  SalesTeam,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "customer/fetchSalesTeam",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/customer/${customerId}/sales_team`
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const fetchCustomerSummaryList = createAsyncThunk<
CustomerSummaryItemResult,
CustomerSummaryRequest,
  { rejectValue: string | ErrorResponse }
>(
  "customer/fetchCustomerSummaryList",
  async (params, { rejectWithValue }) => {
    try {
      const query = objectToQueryString(params);
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/internal/customer/customer_summary${query}`
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const fetchCustomerSummaryListExcel = createAsyncThunk<
  void,
  CustomerSummaryRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchCustomerSummaryListExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/customer/customer_summary/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

export const fetchInsideShortPays = createAsyncThunk<
  TotalByRep[],
  undefined,
  { rejectValue: string | ErrorResponse }
>("customer/fetchInsideShortPays", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/customer/short_pays?salesRepType=inside`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});