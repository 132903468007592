import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorResponse } from "../types/types";
import { axiosWithAuth, errorHandler } from "../app/axios";

type D365SyncResponse = {
  message: string;
};

export const loadProducts = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadProducts", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_products`
    );
    return response.data;
  } catch (error) {
    return errorHandler(error, rejectWithValue);
  }
});

export const loadValidationConfig = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadValidationConfig", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_validation_config`
    );
    return response.data;
  } catch (error) {
    return errorHandler(error, rejectWithValue);
  }
});

export const loadProductSpecs = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadProductSpecs", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_product_specs`
    );
    return response.data;
  } catch (error) {
    return errorHandler(error, rejectWithValue);
  }
});

export const loadCustomers = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadCustomers", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_customers`
    );
    return response.data;
  } catch (error) {
    return errorHandler(error, rejectWithValue);
  }
});

export const clearCaches = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/clearCaches", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/clear_caches`
    );
    return response.data;
  } catch (error) {
    return errorHandler(error, rejectWithValue);
  }
});

export type TierConfigItem = {
  key: string;
  value: string;
  message?: string;
};

type FetchAllTierConfigsResponse = TierConfigItem[];

export const fetchAllTierConfigs = createAsyncThunk<
  FetchAllTierConfigsResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/fetchAllTierConfigs", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/it_admin/tier_config_all`
    );
    return response.data;
  } catch (error) {
    return errorHandler(error, rejectWithValue);
  }
});

type UpdateTierConfigResponse = {
  data: TierConfigItem;
  message: string;
};

export const updateTierConfig = createAsyncThunk<
  UpdateTierConfigResponse,
  TierConfigItem,
  { rejectValue: string | ErrorResponse }
>("itAdmin/updateTierConfig", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/it_admin/tier_config`,
      params
    );
    return {
      data: response.data,
      message: `${response.data.key} successfully updated.`,
    };
  } catch (error) {
    return errorHandler(error, rejectWithValue);
  }
});
