/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  useContext,
  useState,
} from "react";
import tw from "twin.macro";
import Button from "../../../../components/atoms/Button";
import DatePicker from "../../../../components/atoms/DatePicker";
import Input from "../../../../components/atoms/Input";
import Select from "../../../../components/atoms/Select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { ProductSelectionDetails } from "../../../../types/types";
import { dateToString } from "../../../../helpers/dateHelpers";
import LengthComponent from "../../../../components/molecules/LengthComponent";
import DimensionsSelectComponent from "../../myshipping/components/DimensionsSelectComponent";
import { fetchQuoteResults } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { clearDocumentErrors, updateQuoteSearchCriteria } from "../../../../store/document.reducer";
import { PDFContext } from "./InquiryList";
import { lengthToInches } from "../../../../helpers/lengthHelpers";
import { searchParameterExists } from "../../../../helpers/searchParameterExists";

type Props = {
  children?: ReactNode;
};

const InquirySearch: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const selectionData = useAppSelector(
    (state) => state.document.quote.quoteSelectionData
  );
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.quote
  );

  const [customerSearchType, setCustomerSearchType] = useState(false);
  const [quoteNumber, setQuoteNumber] = useState("");
  const [dueDate, setDueDate] = useState<Date | undefined>();
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    grade: "",
    shape: "Unknown",
    height: 0,
    width: 0,
    wall: 0,
  });
  const [length, setLength] = useState({
    feet: 0,
    inches: 0,
    fraction: 0,
  });

  const lengthInInches = lengthToInches(length);
  const ctx = useContext(PDFContext);
  const criteria = {
    myCustomers: customerSearchType,
    quoteNumber: searchParameterExists(quoteNumber) ? quoteNumber : undefined,
    dueDate: dueDate ? dateToString(dueDate) : undefined,
    length: lengthInInches === 0 ? undefined : lengthInInches,
    grade: dimensions?.grade === "" ? undefined : dimensions?.grade,
    shape: dimensions?.shape === "Unknown" ? undefined : dimensions?.shape,
    height: dimensions?.height === 0 ? undefined : dimensions?.height,
    width: dimensions?.width === 0 ? undefined : dimensions?.width,
    decimalWallThickness: dimensions?.wall === 0 ? undefined : dimensions?.wall,
  };

  const resetForm = () => {
    setCustomerSearchType(false);
    setQuoteNumber("");
    setDueDate(undefined);
    setDimensions({
      grade: "",
      shape: "Unknown",
      height: 0,
      width: 0,
      wall: 0,
    });
    setLength({
      feet: 0,
      inches: 0,
      fraction: 0,
    });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Close pdf if open
    if (ctx?.file) {
      ctx.setFile(null);
    }

    dispatch(updateQuoteSearchCriteria({ ...searchCriteria, page: 1}));
    dispatch(fetchQuoteResults({customerId, criteria: { ...criteria, page: 1, pageSize: 25}}));
  };

  return selectionData ? (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <p css={tw`text-nucor-gray mt-2`}>Enter any combination:</p>
      <form onSubmit={submitHandler} css={tw`flex w-full flex-col`}>
        <fieldset css={tw`mt-2`}>
          <label css={tw`block`}>Show</label>
          <Select
            name="customerSearchType"
            minWidth="22ch"
            value={customerSearchType}
            onChange={setCustomerSearchType}
            data={selectionData.customerSearchType.map((item) => ({
              value: item.id,
              label: item.description,
            }))}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="quoteNumber" css={tw`block`}>Inquiry ID #</label>
          <Input
            name="quoteNumber"
            value={quoteNumber}
            onChange={(e) => setQuoteNumber(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="dueDate" css={tw`block`}>Due Date</label>
          <DatePicker
            css={tw`bg-white`}
            name="dueDate"
            value={dueDate}
            onChange={setDueDate}
          />
        </fieldset>

        <LengthComponent
          value={length}
          css={tw`mt-2`}
          onLengthChange={(len) => setLength(len)}
        />
        <DimensionsSelectComponent
          value={dimensions}
          onChange={(dim) => setDimensions(dim)}
        />
        <div css={tw`w-full text-right`}>
          <Button css={tw`mx-4 py-[2px] text-xs`} onClick={resetForm}>
            Clear
          </Button>
          <Button css={tw`m-0 py-[2px] font-normal text-xs mt-3`} type="submit">
            Search
          </Button>
        </div>
      </form>
    </>
  ) : null;
};

export default InquirySearch;
