/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import Select from "../../../../components/atoms/Select";
import HorizontalDimensionSelection, {
  DimensionsSelectRefType,
} from "../../../../components/organisms/HorizontalDimensionSelection";
import { selectInventoryMillsForSelectComponent } from "../../../../store/app.reducer";
import {
  Customer_Customer,
  InventoryStatusType,
  ProductSelectionDetails,
  RequestStatus,
} from "../../../../types/types";
import { selectDefaultMill } from "../../../../store/user.reducer";

type Props = {
  children?: ReactNode;
  onSearch?: (dimensions: ShortsSearchType, isExcel: boolean) => void;
  status: RequestStatus;
  customer?: Customer_Customer;
};

export type ShortsSearchType = Partial<ProductSelectionDetails> & {
  sites?: string[];
  stockStatus: InventoryStatusType;
};

const ShortsListSearchParams: React.FC<Props> = ({
  onSearch,
  status,
  customer,
}) => {
  const productSelectionRef = useRef<DimensionsSelectRefType>(null);
  const millSelection = useAppSelector(selectInventoryMillsForSelectComponent);
  const mills = useAppSelector((state) => state.app.inventorySiteSelections);
  const [excelFlag, setExcelFlag] = useState(false);
  const defaultMill = useAppSelector(selectDefaultMill);
  const [selectedMill, setSelectedMill] = useState(defaultMill);
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    height: 0,
    width: 0,
    wall: 0,
    grade: "",
    shape: "Unknown",
  });

  const searchHandler = () => {
    const sites = mills.find((site) => site.key === selectedMill)?.sites;
    const product = {
      grade: dimensions.grade === "" ? undefined : dimensions?.grade,
      shape: dimensions.shape === "Unknown" ? undefined : dimensions?.shape,
      dimension1: dimensions.width === 0 ? undefined : dimensions?.width,
      dimension2: dimensions.height === 0 ? undefined : dimensions?.height,
      nominalWallThickness:
        dimensions?.wall === 0 ? undefined : dimensions?.wall,
    };
    const currentCriteria = {
      sites,
      stockStatus: "Shorts" as const,
      ...product,
    };
    if (!onSearch) return;
    onSearch(currentCriteria, excelFlag);
  };

  useEffect(() => {
    setSelectedMill("ALL");
    productSelectionRef.current?.reset();
  }, [customer]);

  return (
    <div
      css={tw`flex p-2 bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-1 text-xs items-center`}
    >
      <span>Mill:</span>
      <Select
        name="mill"
        value={selectedMill}
        onChange={(mill: string) => setSelectedMill(mill)}
        minWidth={"20ch"}
        data={millSelection}
      />
      <HorizontalDimensionSelection
        ref={productSelectionRef}
        onChange={(value) => setDimensions(value)}
      />
      <span css={tw`ml-2`}>Run as Excel:</span>
      <input
        type="checkbox"
        name="excel"
        checked={excelFlag}
        onChange={(e) => setExcelFlag(e.target.checked)}
      />
      <Button
        disabled={status === "pending"}
        onClick={searchHandler}
        css={tw`m-0 py-[3px] text-xs font-semibold ml-4`}
        type="button"
      >
        Search
      </Button>
    </div>
  );
};

export default ShortsListSearchParams;
