/** @jsxImportSource @emotion/react */
import React, { ReactNode, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  fetchRobinhoodExcel,
} from "../../../../store/product.actions";
import {
  ErrorResponse,
  ProductSelectionDetails,
  RobinHoodResults,
  RobinHoodViewKeys,
} from "../../../../types/types";
import { fetchRobinhood } from "../../../../store/product.actions";
import Button from "../../../../components/atoms/Button";
import Select from "../../../../components/atoms/Select";
import RobinHoodListResults from "./RobinHoodListResults";
import { selectInventoryMillsForSelectComponent } from "../../../../store/app.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import Toast from "../../../../components/molecules/Toast";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import LengthComponent from "../../../../components/molecules/LengthComponent";
import HorizontalDimensionSelection, { DimensionsSelectRefType } from "../../../../components/organisms/HorizontalDimensionSelection";
import { lengthToInches } from "../../../../helpers/lengthHelpers";

type Props = {
  children?: ReactNode;
};

const RobinHoodList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const defaultMill = useAppSelector(selectDefaultMill);
  const [selectedMill, setSelectedMill] = useState(defaultMill);
  const millSelection = useAppSelector(selectInventoryMillsForSelectComponent);
  const [excelFlag, setExcelFlag] = useState(false);
  const [reportResults, setReportResults] = useState<
    RobinHoodResults | undefined | "excel"
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | string>();
  const productSelectionRef = useRef<DimensionsSelectRefType>(null);
  const [length, setLength] = useState({
    feet: 0,
    inches: 0,
    fraction: 0,
  });
  const lengthInInches = lengthToInches(length);
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    grade: "",
    shape: "Unknown",
    height: 0,
    width: 0,
    wall: 0,
  });
  const [currentView, setCurrentView] = useState<RobinHoodViewKeys>('inD365');
  const [hasSearched, setHasSearched] = useState(false);

  const clickHandler = () => {
    setHasSearched(true);
    setReportResults(undefined);
    if (excelFlag) {
      excelFlag && excelHandler();
    } else {
      searchRobinHoodHandler();
    }
  };

  const searchRobinHoodHandler = () => {
    if (dimensions.compoundProductKey === undefined) return;
    setIsLoading(true);
    dispatch(
      fetchRobinhood({
        compoundProductKey: dimensions.compoundProductKey as string,
        selectedMill: selectedMill,
        length: lengthInInches,
      })
    )
      .unwrap()
      .then((result) => {
        setReportResults(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const excelHandler = () => {
    setIsLoading(true);
    setReportResults("excel");
    dispatch(
      fetchRobinhoodExcel({
        compoundProductKey: dimensions.compoundProductKey as string,
        selectedMill: selectedMill,
        length: lengthInInches,
      })
    )
      .unwrap()
      .then(() => setIsLoading(false))
      .then(() => setReportResults(undefined))
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  return (
    <div css={tw`p-2 w-full`}>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}

      <div css={tw`text-white font-bold bg-nucor-green text-xs py-1 px-2`}>
        <span className="uppercase w-24">Robin Hood</span>
      </div>

      <div
        css={tw`flex py-2 bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-1 text-xs items-center`}
      >
        <HorizontalDimensionSelection
          ref={productSelectionRef}
          onChange={(value) => setDimensions(value)}
        />
        <span css={tw`ml-2`}>Run as Excel:</span>
        <input
          type="checkbox"
          name="excel"
          checked={excelFlag}
          onChange={(e) => {
            setExcelFlag(e.target.checked);
          }}
        />
        <Button
          disabled={dimensions.compoundProductKey === undefined || isLoading}
          onClick={clickHandler}
          css={tw`ml-2`}
          type="button"
        >
          Search
        </Button>
      </div>
      <div css={tw`flex bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-1 text-xs items-center`}>
        <span css={tw`mx-2`}>Mill:</span>
        <Select
          name="mill"
          value={selectedMill}
          onChange={(mill: string) => setSelectedMill(mill)}
          minWidth={"20ch"}
          data={millSelection}
        />
        <label css={tw`mx-2`}>Length:</label>
        <LengthComponent
          value={length}
          onLengthChange={(len) => setLength(len)}
          css={tw`mb-2`}
        />
      </div>

      {!hasSearched && (
        <p css={tw`mt-4 text-nucor-green text-xs font-semibold`}>
          * Select a product and click Search
        </p>
      )}

      {reportResults && reportResults !== 'excel' && (
        <div css={tw`flex gap-2 mt-2 text-xs`}>
          <input
            disabled={false}
            id="inD365"
            name="listType"
            className="peer hover:cursor-pointer disabled:cursor-default"
            type="radio"
            value="inD365"
            checked={currentView == 'inD365'}
            onChange={(e) => setCurrentView(e.target.value as RobinHoodViewKeys)}
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="inD365"
            css={tw`pr-6`}
          >
            Available in D365
          </label>
          <input
            disabled={false}
            id="inPortal"
            name="listType"
            className="peer hover:cursor-pointer disabled:cursor-default"
            type="radio"
            value="inPortal"
            checked={currentView == 'inPortal'}
            onChange={(e) => setCurrentView(e.target.value as RobinHoodViewKeys)}
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="inPortal"
            css={tw`pr-6`}
          >
            Inventory in Portal
          </label>
          <input
            disabled={false}
            id="orderReservations"
            name="listType"
            className="peer hover:cursor-pointer disabled:cursor-default"
            type="radio"
            value="orderReservations"
            checked={currentView == 'orderReservations'}
            onChange={(e) => setCurrentView(e.target.value as RobinHoodViewKeys)}
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="orderReservations"
            css={tw`pr-6`}
          >
            Order Reservations
          </label>
          <input
            disabled={false}
            id="vmiOrderReservations"
            name="listType"
            className="peer hover:cursor-pointer disabled:cursor-default"
            type="radio"
            value="vmiOrderReservations"
            checked={currentView == 'vmiOrderReservations'}
            onChange={(e) => setCurrentView(e.target.value as RobinHoodViewKeys)}
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="vmiOrderReservations"
          >
            VMI Order Reservations
          </label>
        </div>
      )}
      {isLoading && (
        <AnimatedLoadingSpinner css={tw`mt-4 ml-4`} />
      )}
      {reportResults && reportResults !== "excel" && (
        <RobinHoodListResults currentView={currentView} results={reportResults} />
      )}
    </div>
  );

};

export default RobinHoodList;
