/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ChangeShipToDialog from "../../features/portal/stock/prime/inventory/ChangeShipToDialog";
import { changeShipTo } from "../../store/shoppingCart.actions";
import {
  selectCurrentCustomerId,
  selectCurrentShipTo,
} from "../../store/customer.reducer";
import Sprites from "../atoms/Sprites";
import InquiryCart from "./InquiryCart";
import ShipToSelect from "./ShipToSelect";
import ShoppingCartLink from "./ShoppingCartLink";
import { ErrorResponse } from "../../types/types";
import Toast from "./Toast";

type Props = {
  children?: ReactNode;
};

const ShipToCartHeader: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentShipToId = useAppSelector(selectCurrentShipTo);
  const shipToId = useAppSelector((state) => state.customer.currentShipTo);
  const [showModal, setShowModal] = useState(false);
  const [selectedShipTo, setSelectedShipTo] = useState("");
  const [changeShipToError, setChangeShipToError] = useState<ErrorResponse | string>();

  const changeShipToHandler = () => {
    if (!customerId) return;
    setChangeShipToError(undefined);
    dispatch(changeShipTo({ customerId, body: { shipToId: selectedShipTo } }))
      .unwrap()
      .then(() => {
        setShowModal(false);
      })
      .catch((error) => {
        setShowModal(false);
        setChangeShipToError(error);
      });
  };

  return (
    <div css={tw`flex items-center`}>
      {changeShipToError && (
        <Toast
          message={changeShipToError}
          type="error"
          onConfirm={() => setChangeShipToError(undefined)}
        />
      )}

      {showModal && (
        <ChangeShipToDialog
          onCancel={() => setShowModal(false)}
          onConfirm={changeShipToHandler}
          css={[tw`absolute z-50`]}
        />
      )}
      <div css={tw`flex items-center flex-1`}>
        <Sprites icon="shipto" />
        <label css={tw`text-xs mx-1`}>Ship To:</label>
        <ShipToSelect
          value={shipToId}
          onChange={(value) => {
            if (currentShipToId === value) return;
            setSelectedShipTo(value);
            setShowModal(true);
          }}
        />
      </div>
      <ShoppingCartLink />
      <InquiryCart />
    </div>
  );
};

export default ShipToCartHeader;
