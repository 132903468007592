/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useEffect, useRef, useState, useCallback, useMemo } from "react";
import tw, { theme } from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import Input from "../../../../components/atoms/Input";
import Select from "../../../../components/atoms/Select";
import PhoneComponent from "../../../../components/molecules/PhoneComponent";
import {
  Base64File,
  ClaimsInput,
  ErrorResponse,
  ErrorValidation,
  PackingSlipDataForClaim,
  Phone,
  RequestStatus,
} from "../../../../types/types";
import {
  fetchClaimsDocumentsSetup,
  searchClaimsPackingSlipData,
  submitClaimForm,
} from "../../../../store/document.actions";
import { selectClaimsDocumentsSelectionData } from "../../../../store/document.reducer";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import { numberFormatter } from "../../../../helpers/numberFormat";
import CreateClaimFormConfirmation from "./CreateClaimFormConfirmation";
import { FileRejection, useDropzone } from "react-dropzone"
import TextButton from "../../../../components/atoms/TextButton";
import Toast from "../../../../components/molecules/Toast";
import Loading from "../../../../components/atoms/Loading";

type Props = {
  children?: ReactNode;
};

const CreateClaimForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const user = useAppSelector((state) => state.user.currentContext?.user);
  const selectionData = useAppSelector(selectClaimsDocumentsSelectionData);
  const defaultPhone = {
    ...user?.officePhone,
    extension: user?.officePhoneExt ?? "",
  };

  const [contact, setContact] = useState(
    (user && `${user?.firstName} ${user?.lastName}`) ?? ""
  );
  const [phone, setPhone] = useState<
    (Partial<Phone> & { extension?: string }) | undefined
  >(defaultPhone);
  const [site, setSite] = useState("");
  const [notes, setNotes] = useState("");
  const [reason, setReason] = useState("");
  const [requestedResolution, setRequestedResolution] = useState("");

  const [bolNumber, setBolNumber] = useState("");
  const [item, setItem] = useState("");
  const [entireBOL, setEntireBOL] = useState(false);
  const [contactEmail, setContactEmail] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [searchError, setSearchError] = useState("");
  const [searchStatus, setSearchStatus] = useState<RequestStatus>("idle");
  const [submitStatus, setSubmitStatus] = useState<RequestStatus>("idle");
  const [formSubmission, setFormSubmission] = useState<ClaimsInput>();
  const [errors, setErrors] = useState<ErrorResponse | string>();
  const [searchResults, setSearchResults] = useState<
    PackingSlipDataForClaim & { entireBOL?: boolean }
  >();

  const phoneRef = useRef<React.ElementRef<typeof PhoneComponent>>(null);

  const formStyles = css`
    & fieldset label {
      font-weight: bold;
      color: #6c757d;
    }
  `;

  const phoneValid =
    phone?.areaCode?.length === 3 &&
    phone?.exchange?.length === 3 &&
    phone.local4?.length === 4;

  const isValid =
    currentCustomer?.id &&
    contactEmail &&
    contact &&
    phoneValid &&
    reason &&
    requestedResolution &&
    notes &&
    site &&
    searchResults;

  const sitesList =
    selectionData?.sites.map((site) => ({
      value: site.id,
      label: site.description,
    })) ?? [];

  const reasonList =
    selectionData?.reasons.map((r) => ({
      value: r,
      label: r,
    })) ?? [];

  const requestedResolutionsList =
    selectionData?.requestedResolutions.map((r) => ({
      value: r,
      label: r,
    })) ?? [];

  useEffect(() => {
    if (currentCustomer?.id)
      dispatch(fetchClaimsDocumentsSetup(currentCustomer.id));
  }, [currentCustomer]);

  const resetHandler = () => {
    setSite("");
    setNotes("");
    setReason("");
    setRequestedResolution("");
    setBolNumber("");
    setItem("");
    setContactEmail("");
    setEntireBOL(false);
    setSearchResults(undefined);
    setSearchError("");
    setErrors(undefined);
    setMyFiles([]);
  };

  const submitHandler = () => {
    if (!isValid) return;

    Promise.all(
      myFiles.map(
        (f) => readFile(f)
      )
    ).then((base64Files) => {
      const body: ClaimsInput = {
        contactName: contact,
        contactPhone: {
          areaCode: phone.areaCode ?? "",
          exchange: phone.exchange ?? "",
          local4: phone.local4 ?? "",
        },
        contactEmail: contactEmail,
        contactPhoneExtension: phone.extension ?? "",
        site: site,
        description: notes,
        reason: reason,
        requestedResolution: requestedResolution,
        packingSlipNumber: bolNumber,
        packingSlipItem: +item,
        invoiceNumber: invoiceNumber === "" ? undefined : invoiceNumber,
        files: base64Files
      };
  
      setSubmitStatus("pending");
      dispatch(
        /*
        submitClaimFormWithFile({
          customerId: currentCustomer.id,
          body,
          files: myFiles
        })
        */
        submitClaimForm({
          customerId: currentCustomer.id,
          body
        })
      )
        .unwrap()
        .then((result) => {
          resetHandler();
          setFormSubmission(result);
        })
        .catch((error) => {
          setErrors(error);
        }).finally(() => setSubmitStatus("idle"));

    }).catch((error) => {
      setErrors(error);
    });
  };

  const searchPackingSlipDataHandler = () => {
    if (!currentCustomer?.id) return;
    setSearchStatus("pending");
    setInvoiceNumber("");
    setSearchError("");
    setSearchResults(undefined);
    dispatch(
      searchClaimsPackingSlipData({
        customerId: currentCustomer.id,
        body: {
          packingSlipNumber: bolNumber,
          line: entireBOL ? undefined : +item,
        },
      })
    )
      .unwrap()
      .then((result) => {
        setSearchStatus("success");
        if (Object.keys(result).length === 0) {
          setSearchResults(undefined);
        } else {
          setSearchResults({ ...result, entireBOL });
        }
      })
      .catch((error) => {
        setSearchResults(undefined);
        setSearchStatus("error");
        setSearchError(error);
      });
  };

  const invoiceNumberResults =
    searchResults?.invoiceNumbers?.map((invoice) => ({
      value: invoice,
      label: invoice,
    })) ?? [];

  const maxFileSize = 5242880;
  const maxFiles = 5;

  const baseStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    padding: "2px 5px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme("colors.nucor-light-gray"),
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: theme("colors.nucor-gray"),
    outline: "none",
    transition: "border .24s ease-in-out"
  };
    
  const focusedStyle = {
    borderColor: theme("colors.blue.400")
  };
  
  const acceptStyle = {
    borderColor: theme("colors.blue.400")
  };
    
  const rejectStyle = {
    borderColor: theme("colors.blue.400")
  };

  const toBase64 = (file: File): Promise<string | undefined> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const base64 = event.target?.result?.toString().split(",")[1];
      resolve(base64);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

  const readFile = async (file: File) => {
    const s = await toBase64(file);
    const base64File: Base64File = {base64String: s, filename: file.name, contentType: file.type};
    return base64File;
  };
  
  const [myFiles, setMyFiles] = useState([] as File[]);

  const checkTotalFiles = useCallback(() => {
    if (myFiles.length >= maxFiles) {
      return {
        code: "too-many-files",
        message: "A max of " + maxFiles + " files can be attached"
      };
    }
    return null;
  }, [myFiles]);

  const removeFile = (index: number) => {
    const newFiles = [...myFiles]
    newFiles.splice(index, 1)
    setMyFiles(newFiles)
  }

  const convertErrorMessage = (rejection: FileRejection) => {
    let message = rejection.file.name + " cannot be attached: ";
    if ("file-invalid-type" === rejection.errors[0].code) {
      message += "File must be of type .pdf, .jpeg/jpg, .png, .gif, or .tif/.tiff";
    } else if ("file-too-large" === rejection.errors[0].code) {
      message += "File if larger than " + formatBytes(maxFileSize);
    } else {
      message += rejection.errors[0].message;
    }
    return message;
  };

  const onDrop = useCallback((
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {

    const additionalRejectMessages: string[] = [];

    if (acceptedFiles.length > 0) {
      const availableSlots = maxFiles - myFiles.length;
      const endIndex = Math.min(acceptedFiles.length, availableSlots);
      const newFiles = acceptedFiles.slice(0, endIndex);

      if (endIndex < acceptedFiles.length) {
        const newRejectedFiles = acceptedFiles.slice(endIndex);

        newRejectedFiles.forEach((f) => {
          const m = f.name + " cannot be attached: " + "A max of " + maxFiles + " files can be attached";
          additionalRejectMessages.push(m);
        });
      }

      if (newFiles.length > 0) {
        setMyFiles([...myFiles, ...newFiles]);
      }
    }

    if (fileRejections.length > 0 || additionalRejectMessages.length > 0) {
      const validations: ErrorValidation[] = [];

      fileRejections.forEach((rejection) => {
        const message = convertErrorMessage(rejection);
        validations.push({field: "", message: message});
      });
      additionalRejectMessages.forEach((m) => {
        validations.push({field: "", message: m});
      });

      const errors: ErrorResponse = {
        message: "",
        validations: validations,
        path: "",
        status: 400,
        timestamp: new Date().toISOString(),
      };

      setErrors(errors);
    }

  }, [myFiles]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({onDrop,
    validator: checkTotalFiles,
    noClick: true,
    noKeyboard: true,
    maxSize: maxFileSize,
    maxFiles: maxFiles,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
      "image/tiff": [".tif", ".tiff"]
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const formatBytes = (bytes: number) => {
    if(bytes == 0) return "0 Bytes";
    const k = 1024;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  if (formSubmission) {
    return (
      <div css={tw`w-full p-2`}>
        <CreateClaimFormConfirmation
          formSubmission={formSubmission}
          onConfirm={() => setFormSubmission(undefined)}
        />
      </div>
    );
  }

  return (
    <div css={tw`w-full p-2`}>
      <div
        css={tw`bg-nucor-green font-bold text-white px-2 py-1 uppercase text-xs`}
      >
        Claim
      </div>
      <div css={tw`relative`}>
        {submitStatus === "pending" && <Loading>Submitting Claim</Loading>}
        {errors && (
          <Toast onConfirm={() => setErrors(undefined)} type="error" message={errors} />
        )}
        <p css={tw`italic text-xs mt-2`}>
          <span css={tw`text-red-600`}>*</span> indicates required fields
        </p>

        <form css={[tw`mt-2 text-xs flex flex-col gap-2`, formStyles]}>
          <fieldset>
            <label css={tw`inline-block w-44`}>
              Customer <span css={tw`text-red-600`}>*</span>
            </label>
            <span css={tw`font-bold`}>{currentCustomer?.name}</span>
          </fieldset>
          <fieldset>
            <label css={tw`inline-block w-44`}>
              Customer Contact <span css={tw`text-red-600`}>*</span>
            </label>
            <Input
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              css={tw`bg-nucor-yellow`}
            />
          </fieldset>
          <fieldset css={tw`flex items-center`}>
            <label css={tw`w-44`}>
              Phone <span css={tw`text-red-600`}>*</span>
            </label>
            <PhoneComponent
              defaultValue={defaultPhone}
              ref={phoneRef}
              highlight
              onChange={(value) => setPhone(value)}
            />
          </fieldset>
          <fieldset>
            <label css={tw`inline-block w-44`}>
              Email <span css={tw`text-red-600`}>*</span>
            </label>
            <Input
              type="email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              css={tw`bg-nucor-yellow w-[32ch]`}
            />
          </fieldset>
          <fieldset>
            <label css={tw`inline-block w-44`}>
              Division <span css={tw`text-red-600`}>*</span>
            </label>
            <Select
              name="division"
              minWidth="20ch"
              value={site}
              highlight
              onChange={(value: string) => setSite(value)}
              data={[{ value: "", label: "Select" }, ...sitesList]}
            />
          </fieldset>
          <fieldset css={tw`flex`}>
            <label css={tw`inline-block w-44`} htmlFor="notes">
              Description <span css={tw`text-red-600`}>*</span>
            </label>
            <div css={tw`w-full max-w-3xl`}>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                css={tw`w-full border border-[#bbb] h-20 resize-none bg-nucor-yellow resize-none outline-none focus-within:shadow focus-within:border-nucor-focus-border p-1`}
              />
              <p>
                In your detailed description of the issue include PO#, Size
                and Number of pieces.
              </p>
              <p>
                Attach any relevant documentation including photos of material and Tag or Heat # if available.
              </p>
            </div>
          </fieldset>
          <fieldset css={tw`flex`}>
            <label css={tw`w-44`}>
              Claim Reason <span css={tw`text-red-600`}>*</span>
            </label>
            <Select
              name="reason"
              minWidth="12ch"
              highlight
              value={reason}
              data={[{ value: "", label: "Select" }, ...reasonList]}
              onChange={(value: string) => setReason(value)}
            />
          </fieldset>
          <fieldset css={tw`flex`}>
            <label css={tw`w-44`}>
              Requested Resolution <span css={tw`text-red-600`}>*</span>
            </label>
            <Select
              name="requestedResolution"
              minWidth="24ch"
              highlight
              value={requestedResolution}
              data={[{ value: "", label: "Select" }, ...requestedResolutionsList]}
              onChange={(value: string) => setRequestedResolution(value)}
            />
          </fieldset>
          <div css={tw`flex items-center`}>
            <label css={tw`text-nucor-gray w-44 font-semibold`}>
              BOL Search
            </label>
            <div css={tw`flex items-end gap-4`}>
              <fieldset>
                <label css={tw`block font-semibold`}>
                  Number <span css={tw`text-red-600`}>*</span>
                </label>
                <Input
                  name="bolNumber"
                  css={tw`w-[20ch] bg-nucor-yellow`}
                  value={bolNumber}
                  onChange={(e) => setBolNumber(e.target.value)}
                />
              </fieldset>
              <fieldset>
                <label css={tw`block`}>Line #</label>
                <Input
                  name="item"
                  css={tw`w-[7ch]`}
                  value={item}
                  disabled={entireBOL}
                  onChange={(e) => {
                    if (!/^\d*$/.test(e.target.value)) return;
                    setItem(e.target.value);
                  }}
                />
              </fieldset>
              <fieldset css={tw`flex items-center mb-1`}>
                <input
                  id="entireBOL"
                  type="checkbox"
                  checked={entireBOL}
                  onChange={(e) => {
                    setItem("");
                    setEntireBOL(e.target.checked);
                  }}
                />
                <label
                  htmlFor="entireBOL"
                  css={tw`ml-2 text-black! font-normal!`}
                >
                  Applies to entire BOL
                </label>
              </fieldset>
              <Button
                onClick={searchPackingSlipDataHandler}
                disabled={bolNumber === "" || (!item && !entireBOL)}
              >
                Search BOL
              </Button>
            </div>
          </div>
          <div css={tw`flex`}>
            <label css={tw`w-44 font-semibold text-nucor-gray`}>
              BOL Detail
            </label>
            {searchStatus === "pending" && <AnimatedLoadingSpinner />}
            {searchStatus === "idle" && (
              <p>
                Use the criteria above to search for bill of lading detail
                (Item or Applies to entire BOL required)
              </p>
            )}
            {searchStatus === "success" && !searchResults && (
              <p>No bill of lading found</p>
            )}
            {searchError && <p>{searchError}</p>}

            {searchResults && (
              <div>
                <span css={tw`font-semibold text-nucor-gray mr-1`}>
                  Bill of Lading
                </span>
                <span css={tw`mr-3`}>{searchResults.packingSlipNumber}</span>
                <span css={tw`font-semibold text-nucor-gray mr-1`}>
                  Ship Date
                </span>
                <span css={tw`mr-3`}>{searchResults.shipDate}</span>
                <span css={tw`font-semibold text-nucor-gray mr-1`}>
                  Weight
                </span>
                <span css={tw`mr-3`}>
                  {numberFormatter(searchResults.weight)}
                </span>
                {searchResults.entireBOL && (
                  <span css={tw`mr-3 font-bold`}>
                    (Claim for Entire Bill of Lading)
                  </span>
                )}
              </div>
            )}
          </div>
          <div css={tw`flex`}>
            <label css={tw`w-44 font-semibold text-nucor-gray`}>
              Invoice
            </label>
            <Select
              name="invoice"
              minWidth="17ch"
              data={[{ label: "Select", value: "" }, ...invoiceNumberResults]}
              onChange={(num: string) => setInvoiceNumber(num)}
              value={invoiceNumber}
            />
          </div>
          <div css={tw`flex`}>
            <label css={tw`w-44 font-semibold text-nucor-gray`}>
              Attachments
            </label>
            <div {...getRootProps({style, className: "w-full max-w-3xl"})}>
              <input {...getInputProps()} />
              {myFiles.length > 0 && (
                myFiles.map((file, index) => (
                  <div css={tw`flex text-black`} key={index}>
                    <div css={tw`w-56`}>{file.name}</div>
                    <div css={tw`w-28`}>{formatBytes(file.size)}</div>
                    <TextButton onClick={() => removeFile(index)}>Remove File</TextButton>
                  </div>
                ))
              )}
              <p className="py-4">
                Drag &apos;n&apos; drop files here, or click <TextButton onClick={open}>Attach</TextButton> to select files.<br/>
                Supports .pdf, .jpeg/jpg, .png, .gif, and .tif/.tiff files. Max size {formatBytes(maxFileSize)} per file. Max of {maxFiles} files.
              </p>
            </div>
          </div>

          <div css={tw`flex gap-4 mt-4 max-w-[944px] justify-center`}>
            <Button
              onClick={submitHandler}
              disabled={submitStatus === "pending" || !isValid}
            >
              Submit to NTP
            </Button>
            <Button onClick={resetHandler}>Reset</Button>
          </div>
        </form>
      </div>
      
    </div>
  );
};

export default CreateClaimForm;
