import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWithAuth, errorHandler } from "../app/axios";
import {
  CartHeader,
  CartSummaryView,
  ErrorResponse,
  ProductInstructionsInput,
  ProductInstructionsView,
  QuoteItemInput,
} from "../types/types";
import { fetchCartSummary } from "./shoppingCart.actions";
import { RootState } from "./store";

export const fetchQuoteCartSummaries = createAsyncThunk<
  CartSummaryView[],
  void,
  { rejectValue: string | ErrorResponse }
>("quoteCart/fetchQuoteCartSummaries", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/quotes`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const fetchQuoteCart = createAsyncThunk<
  CartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>("quoteCart/fetchQuoteCart", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/quotes/${customerId}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type FetchQuoteCartItemInstructionsRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const fetchQuoteCartItemInstructions = createAsyncThunk<
  ProductInstructionsView,
  FetchQuoteCartItemInstructionsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/fetchQuoteCartItemInstructions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/item/${params.salesOrderItemId}/instructions`
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

type SubmitQuoteCartRequest = {
  customerId: string;
  body: QuoteItemInput[];
};

export const submitQuoteCart = createAsyncThunk<
  CartHeader,
  SubmitQuoteCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/submitQuoteCart",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/submit`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const updateQuoteCartItems = createAsyncThunk<
  CartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/updateQuoteCartItems",
  async (customerId, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const quoteCart = state.quoteCart.currentQuoteCart;
      const itemsArr = quoteCart?.sites.reduce((acc, site) => {
        const items = site.items.map((item) => {
          return {
            id: item.id,
            poLineNumber: item.poLineNumber,
            customerPartNumber: item.customerPartNumber,
            newCustomerPartNumber: item.newCustomerPartNumber,
            requestedShippingDate: item.requestedShippingDate,
          } as QuoteItemInput;
        });
        return [...acc, ...items];
      }, [] as QuoteItemInput[]);
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/quotes/${customerId}`,
        itemsArr
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

type UpdateQuoteCartItemInstructions = {
  customerId: string;
  salesOrderItemId: number;
  body: ProductInstructionsInput;
};

export const updateQuoteCartItemInstructions = createAsyncThunk<
  CartHeader,
  UpdateQuoteCartItemInstructions,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/updateQuoteCartItemInstructions",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/item/${params.salesOrderItemId}/instructions`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const clearQuoteCart = createAsyncThunk<
  void,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/clearQuoteCart",
  async (customerId, { rejectWithValue, dispatch }) => {
    try {
      await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/quotes/${customerId}`
      );
      dispatch(fetchCartSummary(customerId));
      return;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

type RemoveQuoteCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const removeQuoteCartItem = createAsyncThunk<
  CartHeader,
  RemoveQuoteCartItemRequest,
  { rejectValue: string | ErrorResponse }
>("quoteCart/removeQuoteCartItem", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.delete(
      `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/item/${params.salesOrderItemId}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type UpdateQuotePriceRequest = {
  customerId: string;
  salesOrderItemId: number;
  body: {
    totalPrice?: number;
    priceUnit?: string;
  };
};

export const updateQuoteItemPrice = createAsyncThunk<
  CartHeader,
  UpdateQuotePriceRequest,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/updateQuoteItemPrice",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/internal/quotes/${params.customerId}/item/${params.salesOrderItemId}/price`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);
