import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorResponse, OrderDetailPagedResult, RollingStockLengthDetail } from "../types/types";
import { objectToQueryString } from "../helpers/objectToQueryString";
import { axiosWithAuth, errorHandler } from "../app/axios";

export type FetchOpenRollingsOrdersRequest = {
  vmi?: boolean;
  rollingSequence: string;
  rollingCycleId: string;
  identifier?: string;
};

export const fetchOpenRollingsOrders = createAsyncThunk<
  OrderDetailPagedResult,
  FetchOpenRollingsOrdersRequest,
  { rejectValue: string | ErrorResponse }
>("rollings/fetchOpenRollingsOrders", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/order/open_rolling_orders${query}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export type SiteResourcesResult = {
  site: string;
  resources: string[];
}[];

export const fetchSiteResources = createAsyncThunk<
  SiteResourcesResult,
  string | undefined,
  { rejectValue: string | ErrorResponse }
>("rollings/fetchSiteResources", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/rollings/site_resources${
        customerId ? "?customerId=" + customerId : ""
      }`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const fetchRollingStockLengthsDetail = createAsyncThunk<
  RollingStockLengthDetail[],
  FetchOpenRollingsOrdersRequest,
  { rejectValue: string | ErrorResponse }
>("rollings/fetchRollingStockLengthsDetail", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/rollings/stock_lengths${query}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});