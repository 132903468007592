/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../../app/hooks";
import { SiteSelection } from "../../../../../types/types";
import Loading from "../../../../../components/atoms/Loading";
import PrimeProductTableItem from "./PrimeProductTableItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { shallowEqual } from "react-redux";
import { keyFromInventoryItem } from "../../../../../helpers/inventoryKeys";
import { selectFilteredInventoryResults } from "../../../../../store/product.reducer";

type Props = {
  children?: ReactNode;
  oddLengths: boolean;
  site: string;
  showVmi: boolean;
  allowCutInHalf?: boolean;
};

export type FilterParams = {
  selectedMill?: string;
  mills?: SiteSelection[];
  oddLengths?: boolean;
  standardLengths?: number[];
  vmi?: boolean;
};

const PrimeProductTable: React.FC<Props> = ({ oddLengths, site, showVmi, allowCutInHalf }) => {
  const mills = useAppSelector((state) => state.app.inventorySiteSelections);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const { isLoading } = useAppSelector((state) => state.shoppingCart);
  const { inventorySearchStatus } = useAppSelector(
    (state) => state.product.prime
  );
  const standardLengths = useAppSelector(
    (state) => state.product.prime.currentInventoryResults?.standardLengths
  );

  const filteredList = useAppSelector(
    (state) =>
      selectFilteredInventoryResults(state, {
        selectedMill: site,
        mills,
        oddLengths: oddLengths,
        standardLengths,
        vmi: showVmi,
      }),
    shallowEqual
  );

  return (
    <div css={tw`relative w-full min-h-[10rem]`}>
      {(inventorySearchStatus === "pending" || isLoading) && <Loading />}
      <table className="ntp-portal-table" css={tw`text-xs w-full`}>
        <thead>
          <tr>
            <th>Product</th>
            <th css={tw`w-[7%]`}>
              <button
                onClick={() => {
                  setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
                }}
              >
                Length{" "}
                {sortDirection === "asc" ? (
                  <FontAwesomeIcon icon={faSortUp} />
                ) : (
                  <FontAwesomeIcon icon={faSortDown} />
                )}
              </button>
            </th>
            <th css={tw`w-[6%]`}>Mill</th>
            <th css={tw`w-[9%]`}>Lbs. Avail</th>
            <th css={tw`w-[8%]`}>Bdl Wgt</th>
            <th css={tw`w-[7%]`}>Total Pcs</th>
            <th css={tw`w-[7%]`}>Pcs / Bdl</th>
            <th css={tw`w-[9%]`}># of Bdls</th>
            <th css={tw`w-[10%]`}>Bundles Ordered</th>
            <th css={tw`w-[10%]`}>Weight (Lbs)</th>
          </tr>
        </thead>
        <tbody>

          {filteredList && filteredList.length > 0 ? (filteredList
            ?.sort((a, b) => {
              const lengthA = a.inventoryItem.length;
              const lengthB = b.inventoryItem.length;
              if (!lengthA || !lengthB) return 0;
              return sortDirection === "asc"
                ? lengthA - lengthB
                : lengthB - lengthA;
            })
            .map((item) => (
              <PrimeProductTableItem
                key={keyFromInventoryItem(item)}
                item={item}
              />
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={10}>

                {(inventorySearchStatus === "pending" || isLoading) && 
                  <span>&nbsp;</span>
                }
                
                {(inventorySearchStatus !== "pending" && !isLoading) && 
                  <span>No inventory found for the selected criteria.</span>
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {filteredList && filteredList.length > 0 && (
        <>
          <p css={tw`text-xs mt-6 mb-1`}>
            Quantities subject to inventory availability.
          </p>

          {allowCutInHalf && (
            <p css={tw`text-xs mb-1`}>
              Refer to sales for lead time to cut stock in half.
            </p>
          )}

          <p css={tw`text-xs`}>
            Lengths under 20’ are subject to additional charges. Contact ISR for adders.
          </p>
        </>
      )}
    </div>
  );
};

export default PrimeProductTable;
