import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CustomerPart,
  CustomerPartDetail,
  ErrorResponse,
} from "../types/types";
import { axiosWithAuth, blobErrorHandler, errorHandler } from "../app/axios";
import { downloadFile } from "../helpers/downloadExcel";

export const fetchCustomerParts = createAsyncThunk<
  CustomerPart[],
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerParts", async (customerId, { rejectWithValue }) => {
  try {
    const response = axiosWithAuth.get(`
      ${process.env.REACT_APP_API_URL}/parts?customerId=${customerId}`);
    return (await response).data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const fetchCustomerPartDetails = createAsyncThunk<
  CustomerPartDetail[],
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerPartDetails", async (customerId, { rejectWithValue }) => {
  try {
    const response = axiosWithAuth.get(`
      ${process.env.REACT_APP_API_URL}/parts/detail?customerId=${customerId}`);
    return (await response).data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const fetchCustomerPartsExcel = createAsyncThunk<
  void,
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerPartsExcel", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/parts/excel?customerId=${customerId}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

export const fetchCustomerPartsPDF = createAsyncThunk<
  void,
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerPartsPDF", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/parts/pdf?customerId=${customerId}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});
