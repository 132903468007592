import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWithAuth, errorHandler, blobErrorHandler } from "../app/axios";
import { AddUserForm } from "../features/admin/users/add_user/addUserForm";
import { PersonalInfoForm } from "../features/admin/users/edit_user/personalInfoForm";
import { objectToQueryString } from "../helpers/objectToQueryString";
import {
  CustomerIndex,
  UserAdminCreateView,
  UserAdminReadView,
  UserAdminUpdateView,
  UserReadView,
  AccessControl,
  ErrorResponse,
  UserUpdateFormSetup,
} from "../types/types";
import { setCurrentResultsPage } from "./admin.reducer";
import { setAccessControl } from "./user.reducer";
import { downloadFile } from "../helpers/downloadExcel";

export type UserSearchRequest = {
  userId?: string;
  customerId?: string;
  firstName?: string;
  lastName?: string;
  includeDisabled?: boolean;
  email?: string;
  searchType?: string;
};

export const searchUsers = createAsyncThunk<
  UserReadView[],
  UserSearchRequest,
  { rejectValue: string | ErrorResponse }
>("admin/searchUsers", async (searchDetails, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setCurrentResultsPage(0));
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/admin/user/search${objectToQueryString(
        searchDetails
      )}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const getUserInfo = createAsyncThunk<
  UserAdminReadView,
  number,
  { rejectValue: string | ErrorResponse }
>("admin/getUserInfo", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/admin/user/${id}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const searchCustomers = createAsyncThunk<
  CustomerIndex[],
  string,
  { rejectValue: string | ErrorResponse }
>("admin/searchCustomers", async (searchTerm, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth(
      `${process.env.REACT_APP_API_URL}/admin/user/search_customers?fuzzyName=${searchTerm}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const addUser = createAsyncThunk<
  UserAdminCreateView,
  AddUserForm,
  { rejectValue: string | ErrorResponse }
>("admin/addUser", async (user: AddUserForm, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/admin/user`,
      user
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const updateUser = createAsyncThunk<
  UserAdminUpdateView,
  PersonalInfoForm,
  { rejectValue: string | ErrorResponse }
>("admin/updateUser", async (user: PersonalInfoForm, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/admin/user/${user.userId}`,
      user
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const updateAccessControl = createAsyncThunk<
  void,
  AccessControl,
  { rejectValue: string | ErrorResponse }
>(
  "admin/updateAccessControl",
  async (settings, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/admin/update_access_control`,
        settings
      );
      dispatch(setAccessControl(response.data));
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

export const exportUsersAsExcel = createAsyncThunk<
  void,
  UserSearchRequest,
  { rejectValue: string | ErrorResponse }
>("admin/exportUsersAsExcel", async (details, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${
        process.env.REACT_APP_API_URL
      }/admin/user/search/excel${objectToQueryString(details)}`,
      { responseType: "blob" }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

export const getUserInfoFormSetup = createAsyncThunk<
  UserUpdateFormSetup,
  void,
  { rejectValue: string | ErrorResponse }
>("admin/getUserInfoFormSetup", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/admin/user/setup`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const priceSheetItemsExcel = createAsyncThunk<
  void,
  undefined,
  { rejectValue: string | ErrorResponse }
>("admin/priceSheetItemsExcel", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/admin/pricing/priced_products/excel`,
      { responseType: "blob" }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});
